<template>
  <div>
    <v-list flat dense>
      <draggable v-model="services" @start="startTouch" @end="endTouch" handle=".draggable-area" ghost-class="ghost" ref="draggableComponent">
        <div v-for="(service) in services" v-bind:key="service.id">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon small class="draggable-area mx-2 mr-4">mdi-menu</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div>
                <v-container v-if="!editing || editService.id != service.id" fluid class="pa-0">
                  <v-row class="ma-0 pa-0">
                    <v-col class="pa-0">
                      {{service.name}}
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </draggable>
    </v-list>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import DurationPicker from '@/components/common/DurationPicker.vue'
import StandardDialog from '@/components/common/StandardDialog.vue'
import dateUtils from '@/mixins/common/dateUtils.js'
import serviceServiceTypes from "@/services/serviceTypes/serviceTypes.service.js";

export default {
  name: "OrderServiceForm",
  mixins: [dateUtils],
  components: {
    draggable,
    DurationPicker,
    StandardDialog
  },
  data: function() {
    return {
      services: [],
      drag: false,
      editService: undefined,
      createService: {},
      newServiceDialog: false,
      editValid: false,
      createValid: false,
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      oldService: undefined
    }
  },
  props: {
    id:{
    },
    generateIdWhenCreate: {
      type: Boolean,
      default: false
    },
    bus:{
      default: function() {
        return new Vue()
      }
    }
  },
  mounted: async function() {
    this.bus.$on('changeOrder', this.changeOrder)
    this.bus.$on('closeOrder', this.closeOrder)
    await serviceServiceTypes.getServices(this.id).then(res=>{
      this.services = res[0].services
    })
    let counter = 0
/*     this.services.sort((a,b) => {
      if(a.order !== null && a.order !== undefined && b.order !== null && b.order !== undefined)
        return a.order - b.order
      else if(a.order !== null && a.order !== undefined)
        return -1
      else if(b.order !== null && b.order !== undefined)
        return 1
      else
        return 0
    }) */
    this.services.forEach(elem => {
      elem.order = counter
      counter++
    })
    this.oldService = this.services
  },
  methods: {
    edit(service) {
      this.editService = {...service}
    },
    submitCreate() {
      if(!this.createService.minutes){
        alert('Inserire una durata')
      } else {
      if(this.generateIdWhenCreate) {
        let id = this.getUniqId()
        this.services.push({id: id, ...this.createService})
      } else {
        this.services.push({...this.createService})
      }

      this.$emit('create-phase', this.createService)
      this.newServiceDialog = false
      this.createService = {}
      this.$refs.newForm.resetValidation()
      this.$emit('input', this.services)
      }
    },
    submitEdit() {
      let index;
      for(var i = 0; i < this.services.length; i++) {
        if(this.services[i].id == this.editService.id) {
          index = i
        }
      }
      if(index != undefined) {
        this.services[index] = Object.assign({}, this.services[index], this.editService)
        this.$emit('update-phase', this.editService)
        this.$emit('input', this.services)
      }
      this.editService = undefined
    },
    getUniqId() {
      let max = 0;
      for(let i = 0; i < this.services.length; i++) {
        if(max == undefined || parseInt(this.services[i].id) > max) {
          max = parseInt(this.services[i].id)
        }
      }
      return max + 1
    },
    startTouch() {
      this.drag = true
    },
    endTouch(context) {
      this.drag = false
      // this.$emit('input', this.services)
      // this.$emit('move-phase', this.services[context.newIndex], context.newIndex)
    },
    changeOrder(){
      let counter = 0
      this.services.forEach(elem => {
        elem.order = counter
        counter++
      })
      
      this.oldService = this.services

      let mapped = this.services.map(elem => {return {id: elem.id, order: elem.order}})
      serviceServiceTypes.updateOrderServices(mapped)
    },
    closeOrder(){
      this.services = this.oldService
    }
  },
  computed: {
    editing() {
      return !!this.editService
    }
  },
  watch: {
    value(newValue) {
      this.services = newValue
    }
  }
}
</script>

<style>

.draggable-area {
	cursor: move
}

.ghost {
	background-color: #b6b6b6;
	opacity: 0.2;
}

</style>