<template>
  <div>
    <v-form v-model="formValid">
      <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
      <v-row class="py-1">
        <v-col cols="5">
          <v-text-field
            v-model="name"
            :rules="[max255CharRule, presenceRule]"
            dense
            filled
            label="Nome"
            hide-details="auto"
            @input="updateField('name', $event)"
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model="description"
            :rules="[max255CharRule, presenceRule]"
            dense
            filled
            label="Descrizione"
            hide-details="auto"
            @input="updateField('description', $event)"
          ></v-text-field>
        </v-col>
        <v-col cols="1" class="d-flex align-center justify-center">
          <StandardColorPicker 
            v-model="color"
            @input="updateField('color', $event)"
          ></StandardColorPicker>
          <div class="ml-2 text-body-2">
            Colore
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="highFidelityDayFrom"
            dense
            filled
            label="Da (Alta Fidelizzazione)"
            hide-details="auto"
            type="number"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="highFidelityDays"
            dense
            filled
            label="A (Alta Fidelizzazione)"
            hide-details="auto"
            type="number"
            :min="highFidelityDayFrom"
            :rules="[greaterThan0]"
            @input="updateField('highFidelityDays', $event)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="mediumFidelityDayFrom"
            dense
            filled
            label="Da (Media Fidelizzazione)"
            hide-details="auto"
            type="number"
            disabled
            :rules="[greaterThan0]"
            :min="0"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="mediumFidelityDays"
            dense
            filled
            label="A (Media Fidelizzazione)"
            hide-details="auto"
            type="number"
            :min="mediumFidelityDayFrom"
            :rules="[greaterThan0]"
            @input="updateField('mediumFidelityDays', $event)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="lowFidelityDayFrom"
            dense
            filled
            label="Da (Bassa Fidelizzazione)"
            hide-details="auto"
            type="number"
            disabled
            :rules="[greaterThan0]"
            :min="mediumFidelityDayFrom"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="lowFidelityDays"
            dense
            filled
            label="A (Bassa Fidelizzazione)"
            hide-details="auto"
            disabled
            type="text"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-btn :color="color" @click="openOrderService">
            Cambia Ordine Servizi
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!--<StandardDialog
      v-model="confirmDialog"
      :dialog-height="null"
      title="Conferma Modifica"
      dialog-width="500px"
    >
      <span>
        Attenzione! Si sta modificando un parametro legato alla fidelizzione. Continuare?
      </span>
      <template v-slot:footer-actions>
        <v-btn color="error" text @click="handleClosure"
          >Chiudi</v-btn
        >
        <v-btn
          color="default"
          text
          @click="handleFidelityChanges"
        >Conferma</v-btn
      >
      </template>
    </StandardDialog>-->

    <StandardDialog 
        v-model="orderService" 
        :dialog-height="null" 
        title="Ordina servizi" 
        close-button-text="Chiudi"
      >
        <OrderServiceForm
        :id="id"
        :bus="bus">

        </OrderServiceForm>
        <template v-slot:footer-actions>
            <v-btn color="error" text @click="closeOrderService"
              >Chiudi</v-btn
            >
            <v-btn
              color="default"
              text
              @click="changeOrder"
            >Conferma</v-btn
          >
          </template>
      </StandardDialog>
  </div>
</template>

<script>
import Vue from 'vue'
import serviceTypeForm from '@/services/serviceTypes/serviceType.form.js'
import StandardColorPicker from '@/components/common/StandardColorPicker.vue'
import StandardDialog from "@/components/common/StandardDialog.vue";
import OrderServiceForm from '@/components/areas/registries/serviceTypes/OrderServiceForm.vue'
export default {
  name: "GeneralFormTab",
  components: {
    StandardColorPicker,
    StandardDialog,
    OrderServiceForm
  },
  data: function() {
    return {
      name: undefined,
      description: undefined,
      highFidelityDayFrom: 1,
      highFidelityDays: undefined,
      mediumFidelityDayFrom: 1,
      mediumFidelityDays: undefined,
      lowFidelityDayFrom: 1,
      lowFidelityDays: '365',
      color: undefined,
      loadingServiceType: false,
      formValid: false,
      confirmDialog: false,
      id: undefined,
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      greaterThan0: (v) => {
        return (v == undefined || isNaN(v) || v == '' || v >= 1 ) || "Importo non valido";
      },
      orderService: false
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    passChange: {
      type: Function
    }
  },
  mounted: function() {
    this.handleObjectChanges(serviceTypeForm.serviceType)
    var self = this
    serviceTypeForm.on('update', function(data) {
      self.handleObjectChanges(data.serviceType)
      self.id = data.serviceType.id
    })
    if(!this.bus)
      this.bus = new Vue()
  },
  methods: {
    fields() {
      return ['name', 'description', 'highFidelityDays', 'mediumFidelityDays', 'color']
    },
    openConfirmDialog(key, value) {
      this.confirmDialog = true
      this.confirmKey = key
      this.confirmValue = value
    },
    handleClosure() {
      this.confirmDialog = false
      this.handleObjectChanges(serviceTypeForm.serviceType)
    },
    handleFidelityChanges() {
      if (this.confirmKey == 'highFidelityDays') {
        this.mediumFidelityDayFrom = Number(this.confirmValue) + 1
        this.updateField(this.confirmKey, this.confirmValue)
      }
      else if (this.confirmKey == 'mediumFidelityDays') {
        this.lowFidelityDayFrom = Number(this.confirmValue) + 1
        this.updateField(this.confirmKey, this.confirmValue)
      }
      this.confirmDialog = false
    },
    updateField(key, value) {
      if(key == 'highFidelityDays' || key == 'mediumFidelityDays'){
        this.passChange()
        if(value == "")
          value = null
        serviceTypeForm.updateField(key, value)
      }
      else
        serviceTypeForm.updateField(key, value)
    },
    handleObjectChanges(serviceType) {
      const fields = this.fields()
      const newValKeys = Object.keys(serviceType)
      for(let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if(newValKeys.includes(field) && serviceType[field] != this[field]) {
          if (field == 'highFidelityDays') {
            if (!serviceType[field])
              this.mediumFidelityDayFrom = 1
            else {
              this.mediumFidelityDayFrom = Number(serviceType[field]) + 1
            }
          }
          if (field == 'mediumFidelityDays') {
            if (!serviceType[field])
              this.lowFidelityDayFrom = 1
            else
              this.lowFidelityDayFrom = Number(serviceType[field]) + 1
          }
          
          this[field] = serviceType[field]
        }
      }
    },
    openOrderService(){
      this.orderService = true
    },
    closeOrderService(){
      this.orderService = false
      this.bus.$emit('closeOrder')
    },
    changeOrder(){
      this.orderService = false
      this.bus.$emit('changeOrder')
    }
  },
  watch: {
    formValid(newVal) {
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      serviceTypeForm.setValid(newVal)
    },
    highFidelityDays(newVal){
      this.mediumFidelityDayFrom = parseInt(newVal) + 1
    },
    mediumFidelityDays(newVal){
      this.lowFidelityDayFrom = parseInt(newVal) + 1
    }
  }
};
</script>